<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type == 'edit' ? ($t('cip.plat.reportcenter.mediumScreen.title.editHeadTitle')) : (this.type == 'view' ? ($t('cip.plat.reportcenter.mediumScreen.title.viewHeadTitle')) : $t('cip.plat.reportcenter.mediumScreen.title.addHeadTitle'))"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>

    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>

<script>
let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import ServerNameEnum from "@/util/ServerNameEnum";
import {add, getDetail} from "@/api/system/mediumscreen";
import {mapGetters} from "vuex";

export default {
  name: "mediumscreenAdd",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {
        filePath: '',
      },
    }
  },
  computed: {
    ...mapGetters(['language']),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            // btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      let validatePass = (rule, value, callback) => {
        if (value != '') {
          let val = JSON.parse(value)
          let language = this.language
          let val2 = val[language]
          if (val2.substring(0, 1) == " " || val2.substring(val2.length - 1) == " ") {
            callback(new Error(this.$t('cip.cmn.rule.deleteWarning')))
          }
          if (val2.length > 60) {
            callback(new Error(this.$t('cip.cmn.rule.nameLength6Warning')));
          } else {
            callback();
          }
        } else {
          callback(new Error(this.$t('cip.plat.reportcenter.mediumScreen.field.name') + this.$t('cip.cmn.rule.noEmptyWarning')));
        }
      };
      return [
        {
          label: this.$t('cip.plat.reportcenter.mediumScreen.field.name'),
          prop: 'name',
          labelWidth: 130,
          span: 8,
          isGlobal: true,
          maxlength: 100,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.reportcenter.mediumScreen.field.name`),
          read: !['add', 'edit'].includes(this.type),
          rules: [{required: true, validator: validatePass, trigger: 'blur'}]
        },

        {
          label: this.$t('cip.plat.reportcenter.mediumScreen.field.code'),
          prop: 'code',
          labelWidth: 130,
          span: 8,
          maxlength: 100,
          placeholder: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.reportcenter.mediumScreen.field.code`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.reportcenter.mediumScreen.field.code'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.reportcenter.mediumScreen.field.urlPath'),
          labelWidth: 130,
          maxlength: 200,
          prop: 'urlPath',
          type: 'textarea',
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.reportcenter.mediumScreen.field.urlPath`),
        },
        {
          label: this.$t('cip.plat.reportcenter.mediumScreen.field.remark'),
          labelWidth: 130,
          maxlength: 200,
          prop: 'remark',
          type: 'textarea',
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.reportcenter.mediumScreen.field.remark`),
        },
      ]
    }
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      console.log(this.dataForm.id)
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })

    },

    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading()
          add({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }

              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.dataForm = data;
                this.initData();
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close()
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
